<template>
  <div
    v-if="show"
    ref="create-loan-modal"
    class="modal modal__right"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    id="addModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Loan Product
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="save()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--CREATE LOAN PRODUCT MODAL-->
    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a class="modal__close modal__close--black" @click="$emit('close')">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">
            Create New Loan Account
          </h5>
        </div>
        <div class="modal__body">
          <form>
            <div class="form__item">
              <label class="form__label text--regular text--black"
                >Account Recipient</label
              >
              <p
                class="text--600 text--capital text--large text--primary"
                v-if="whatPage === 'customer'"
              >
                {{ accountRecipient }}
              </p>
              <template v-else>
                <multiselect
                  v-model="selectedCustomer"
                  id="ajax"
                  label="customerFirstName"
                  track-by="customerKey"
                  placeholder="Type to search for client by name"
                  open-direction="bottom"
                  :options="customers"
                  :multiple="false"
                  :searchable="true"
                  :loading="loadingCustomers"
                  :internal-search="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :options-limit="300"
                  :limit="3"
                  :max-height="600"
                  :show-no-results="false"
                  :hide-selected="true"
                  @search-change="searchCustomers"
                >
                  <template slot="tag" slot-scope="{ option, remove }"
                    ><span class="custom__tag"
                      ><span>{{
                        option.customerFirstName + " " + option.customerLastName
                      }}</span
                      ><span class="custom__remove" @click="remove(option)"
                        >❌</span
                      ></span
                    ></template
                  >

                  <span slot="noResult"
                    >Oops! No elements found. Consider changing the search
                    query.</span
                  >
                </multiselect>
              </template>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">Product</label>
                  <select class="select select--lg" v-model="productKey">
                    <option value="null" disabled selected v-if="!loading">
                      Select Product
                    </option>
                    <option value="null" disabled selected v-else>
                      Loading...
                    </option>
                    <template v-if="!loading">
                      <option
                        v-for="item in products"
                        :key="item.loanProductKey"
                        v-bind:value="item.loanProductKey"
                      >
                        {{ item.productName }}
                      </option>
                    </template>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">Display Name</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter Display Name"
                    v-model="loanProductName"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">Product Type</label>
                  <p class="text--black text--600 text--capital text--regular">
                    {{ loanProductType }}
                  </p>
                  <p class="text--black text--500 text--small">
                    {{ loanProductDescription }}
                  </p>
                </div>
              </div>
            </div>

            <label class="form__label text--black text--capital mb-3"
              >state</label
            >
            <ul id="accordion">
              <!--ACCOUNT TERMS-->
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#accountSettings"
                  aria-expanded="false"
                  aria-controls="accountSettings"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    account terms
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="accountSettings"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Loan Amount</label>
                          <input
                            type="number"
                            :min="minLoanAmount"
                            :max="maxLoanAmount"
                            class="
                              input input--right
                              form__input form__input--lg
                            "
                            placeholder="Enter Loan Amount"
                            v-model.number="loanAmount"
                          />
                          <small
                            >Min: {{ toNaira(minLoanAmount) }} Max:
                            {{ toNaira(maxLoanAmount) }}</small
                          >
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Interest Rate</label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span
                                class="
                                  w-100
                                  d-flex
                                  text--white text--600 text--medium
                                  align-items-center
                                  justify-content-center
                                "
                              >
                                %
                              </span>
                            </div>
                            <input
                              type="text"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              placeholder="Enter interest rate per month"
                              v-model="interestRate"
                            />
                          </div>
                          <small
                            >Min: {{ loanMinInterestRate }}% Max:
                            {{ loanMaxInterestRate }}%</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label"
                          >Repaid Every {{ loanInterestType }}</label
                        >
                        <input
                          type="text"
                          class="input input--right form__input form__input--lg"
                          v-bind:placeholder="
                            'Enter number of ' + loanInterestType
                          "
                          v-model="repaymentPeriodUnit"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label"
                          >Over how many installment</label
                        >
                        <input
                          type="text"
                          class="input input--right form__input form__input--lg"
                          v-model="repaymentInstallments"
                          placeholder="Enter how many installments"
                        />
                        <small
                          >Min: {{ minFirstRepaymentDueDateOffset }} Max:
                          {{ maxFirstRepaymentDueDateOffset }}</small
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label"
                          >Arrears Tolerance Period</label
                        >
                        <input
                          type="text"
                          class="input input--right form__input form__input--lg"
                          placeholder="Enter number of days"
                          v-model="arrearsTolerancePeriod"
                        />
                        <small
                          >Min:
                          {{ minTolerancePercentageOfOutsatndingPrincipal }}
                          days Max:
                          {{ maxTolerancePercentageOfOutsatndingPrincipal }}
                          days</small
                        >
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label"
                          >Arrears Tolerance Amount</label
                        >
                        <input
                          type="text"
                          class="input input--right form__input form__input--lg"
                          placeholder="Enter Arrears Tolerance Amount"
                          v-model="arrearsTolerancePeriod"
                        />
                        <small>% of outstanding principal</small>
                      </div>
                    </div>
                  </div>
                  <div class="form__item">
                    <label class="form__label"
                      >Interest Calculation Method</label
                    >
                    <p
                      class="text--black text--600 text--capital text--regular"
                    >
                      {{ loanInterestCalculationMethod }}
                    </p>
                  </div>
                </div>
              </div>
              <!--DISBURSEMENT DETAILS-->
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#disbursement"
                  aria-expanded="false"
                  aria-controls="disbursement"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    Disbursement Details
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="disbursement"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="form__item">
                      <label class="form__label">Channel</label>
                      <select class="select select--lg" required>
                        <option value="" disabled selected>
                          Select channel
                        </option>
                        <option value="">option 1</option>
                        <option value="">option 2</option>
                        <option value="">option 3</option>
                      </select>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >Anticipated Disbursement</label
                          >
                          <input
                            type="date"
                            class="
                              input input--right
                              form__input form__input--lg
                            "
                            placeholder="Enter number of days"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >First Repayment Date</label
                          >
                          <input
                            type="date"
                            class="
                              input input--right
                              form__input form__input--lg
                            "
                            placeholder="Enter Arrears Tolerance Amount"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- SCHEDULE PREVIEW-->
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#schedule"
                  aria-expanded="false"
                  aria-controls="schedule"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    Schedule Preview
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="schedule" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="form__item">
                      <label class="form__label">Expected Closure</label>
                      <p
                        class="
                          text--black text--600 text--capital text--regular
                        "
                      >
                        21-04-2021
                      </p>
                    </div>
                    <table class="customtable w-100">
                      <thead>
                        <tr>
                          <th
                            class="
                              table__header__text table__text
                              text--capital text--tiny
                            "
                            style="width: 5%"
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            class="
                              table__header__text table__text
                              text--capital text--tiny
                            "
                          >
                            Installment Date
                          </th>
                          <th
                            scope="col"
                            class="
                              table__header__text table__text
                              text--capital text--tiny
                            "
                          >
                            Principal
                          </th>
                          <th
                            scope="col"
                            class="
                              table__header__text table__text
                              text--capital text--tiny
                            "
                          >
                            Interest
                          </th>
                          <th
                            scope="col"
                            class="
                              table__header__text table__text
                              text--capital text--tiny
                            "
                          >
                            Fees
                          </th>
                          <th
                            scope="col"
                            class="
                              table__header__text table__text
                              text--capital text--tiny
                            "
                          >
                            Payment Due
                          </th>
                          <th
                            scope="col"
                            class="
                              table__header__text table__text
                              text--capital text--tiny
                            "
                          >
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="5">
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >0</span
                            >
                          </td>
                          <td></td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦1,000,00</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >1</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >21-01-2021</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦250,000.00</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦50,000.00</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦0.00</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦50,000.00</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦1,000,00</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >2</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >21-01-2021</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦250,000.00</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦50,000.00</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦0.00</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦50,000.00</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦1,000,00</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >3</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >21-01-2021</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦250,000.00</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦50,000.00</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦0.00</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦50,000.00</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦1,000,00</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >4</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >21-01-2021</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦250,000.00</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦50,000.00</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦0.00</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦50,000.00</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦1,000,00</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >5</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >21-01-2021</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦250,000.00</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦50,000.00</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦0.00</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦50,000.00</span
                            >
                          </td>
                          <td>
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--grey
                              "
                              >₦1,000,00</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td class="border-0"></td>
                          <td class="border-0">
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--success
                              "
                              >total</span
                            >
                          </td>
                          <td class="border-0">
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--success
                              "
                              >₦100,000,000</span
                            >
                          </td>
                          <td class="border-0">
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--success
                              "
                              >₦50,000.00</span
                            >
                          </td>
                          <td class="border-0">
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--success
                              "
                              >₦0.00</span
                            >
                          </td>
                          <td class="border-0" colspan="2">
                            <span
                              class="
                                table__body__text table__text
                                text--capital text--tiny text--success
                              "
                              >₦50,000.00</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="text-right mt-3">
                      <button
                        class="
                          button button--grey
                          text--600 text--black text--small
                        "
                      >
                        Preview Schedule
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ASSOCIATION-->
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#association"
                  aria-expanded="false"
                  aria-controls="association"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    Association
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="association" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-12">
                        <div class="form__item">
                          <label class="form__label">Branches</label>
                          <select class="select select--lg" required>
                            <option value="null" disabled selected>
                              Select Association Branches
                            </option>
                            <option
                              v-for="item in branchList"
                              :key="item.branchKey"
                              v-bind:value="item.branchName"
                            >
                              {{ item.branchName }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--ACCOUNT NOTES-->
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#decriptions"
                  aria-expanded="false"
                  aria-controls="decriptions"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    Account Notes
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="decriptions" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="form__item">
                      <textarea
                        cols="5"
                        rows="5"
                        class="input form__input"
                        v-model="notes"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
            <div class="form__item">
              <div class="form__action">
                <button
                  id="save-changes"
                  type="button"
                  class="button form__button form__button--lg"
                  @click="confirmSave()"
                >
                  {{ process ? "Saving..." : "save changes" }}
                </button>
                <a
                  role="button"
                  class="
                    modal__close
                    form__action__text form__action__text--danger
                    text--capital
                    ml6
                  "
                  @click="$emit('close')"
                >
                  cancel
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
function initialState() {
  return {
    confirm: false,
    showInactiveFees: true,
    process: false,
    productKey: null,
    loanProductName: "",
    loanProduct: null,
    minLoanAmount: null,
    maxLoanAmount: null,
    whatPage: "general",
    branchList: [],
    customers: [],
    loadingCustomers: false,
    selectedCustomer: null,
    loanAccountKey: "string",
    accountArrearsSettingsKey: "string",
    accountHolderKey: "string",
    accountHolderType: "string",
    accountsIntegerIdx: 0,
    accountState: "string",
    accountSubState: "string",
    accruedInterest: 0,
    accruedPenalty: 0,
    accrueInterestAfterMaturity: true,
    accrueLateInterest: true,
    activationTransactionKey: "string",
    allowOffset: true,
    applyInterestOnPrepaymentMethod: "string",
    approvedDate: "2021-03-06T22:34:27.025Z",
    arrearsTolerancePeriod: 0,
    assignedBranchKey: "string",
    assignedCentreKey: "string",
    assignedUserKey: "string",
    closedDate: "2021-03-06T22:34:27.025Z",
    defaultFirstRepaymentDueDateOffset: 0,
    disbursementDetailsKey: "string",
    elementsRecalculationMethod: "string",
    feesBalance: 0,
    feesDue: 0,
    feesPaid: 0,
    fixedDaysOfMonth: "string",
    futurePaymentsAcceptance: "string",
    gracePeriod: 0,
    gracePeriodType: "string",
    hasCustomSchedule: true,
    holdBalance: 0,
    uniqueLoanId: "string",
    interestApplicationMethod: "string",
    interestBalance: 0,
    interestBalanceCalculationMethod: "string",
    interestCalculationMethod: "",
    interestChargeFrequency: "string",
    interestCommission: 0,
    interestDue: 0,
    interestFromArrearsAccrued: 0,
    interestFromArrearsBalance: 0,
    interestFromArrearsDue: 0,
    interestFromArrearsPaid: 0,
    interestPaid: 0,
    interestRate: 0,
    interestRateReviewCount: 0,
    interestRateReviewUnit: "string",
    interestRateSource: "string",
    interestRoundingVersion: "string",
    interestSpread: 0,
    interestType: "string",
    lastAccountAppraisalDate: "2021-03-06T22:34:27.025Z",
    lastInterestAppliedDate: "2021-03-06T22:34:27.025Z",
    lastInterestReviewDate: "2021-03-06T22:34:27.025Z",
    lastLockedDate: "2021-03-06T22:34:27.025Z",
    lastSetToArrearsDate: "2021-03-06T22:34:27.025Z",
    lastTaxRateReviewDate: "2021-03-06T22:34:27.025Z",
    latePaymentsRecalculationMethod: "string",
    lineOfCreditKey: "string",
    loanAmount: 0,
    loanGroupKey: "string",
    loanName: "string",
    loanPenaltyCalculationMethod: "string",
    lockedOperations: "string",
    migrationEventKey: "string",
    notes: "",
    paymentMethod: "string",
    penaltyBalance: 0,
    penaltyDue: 0,
    penaltyPaid: 0,
    penaltyRate: 0,
    periodicPayment: 0,
    prePaymentAcceptance: "string",
    prePaymentRecalculationMethod: "string",
    principalBalance: 0,
    principalDue: 0,
    principalPaid: 0,
    principalPaidInstallmentStatus: "string",
    principalPaymentSettingsKey: "string",
    principalRepaymentInterval: 0,
    productTypeKey: "string",
    reDrawBalance: 0,
    repaymentInstallments: 0,
    repaymentPeriodCount: 0,
    repaymentPeriodUnit: "",
    repaymentScheduleMethod: "",
    reScheduledAccountKey: "string",
    scheduleDueDatesMethod: "string",
    shortMonthHandlingMethod: "string",
    taxRate: 0,
  };
}
import { mapState } from "vuex";
import LoanApiService from "@/core/services/api.loan.service";
import branchService from "@/core/services/organization-service/branches.service";
import customerService from "@/core/services/general-setup-service/customer.service";
import { GET_ALL_LOAN_PRODUCTS } from "@/core/services/store/types";
import Multiselect from "vue-multiselect";
export default {
  name: "CreateLoanAccount",
  components: {
    Multiselect,
  },
  props: {
    show: Boolean,
    close: Function,
    customer: {
      required: false,
      type: Object,
      default: null,
    },
  },
  data: function () {
    return initialState();
  },
  methods: {
    toNaira(value) {
      const val = new Intl.NumberFormat().format(value);
      return "₦" + val;
    },
    getLoanProducts() {
      this.$store.dispatch(GET_ALL_LOAN_PRODUCTS);
    },
    searchCustomers(search) {
      this.loadingCustomers = true;
      const self = this;
      const p = {
        customerTypeKey: null,
        filter: {
          mode: "And",
          filterLines: [
            {
              property: "customerFirstName",
              dataType: "string",
              lineOperator: "string",
              valueFields: [search],
            },
            {
              property: "customerLastName",
              dataType: "string",
              lineOperator: "string",
              valueFields: [search],
            },
          ],
        },
        pageIndex: 1,
        pageSize: 1000,
        customerStatus: null,
      };
      customerService
        .getCustomers(p)
        .then((res) => res.data.data.items)
        .then((res) => {
          self.loadingCustomers = false;
          self.customers = res;
        })
        .catch(() => {
          self.loadingCustomers = false;
        });
    },
    resetWindow: function () {
      Object.assign(this.$data, initialState());
    },
    confirmSave() {
      if (!this.confirm) {
        this.confirm = true;
      } else {
        this.confirm = false;
      }
    },
    save: function () {
      this.process = true;
      // const self = this;
      const copyOfPayload = Object.assign(this.$data);

      LoanApiService.post("LoanAccount/addloanaccount", copyOfPayload)
        .then((res) => res.data)
        .then((res) => {
          this.confirm = false;
          this.process = false;
          if (res.status) {
            this.$emit("alertSuccess", res.message);
            this.resetWindow();
            this.$emit("close");
            this.$emit("reload");
          } else {
            this.$emit("alertError", res.message);
          }
        })
        .catch((e) => {
          this.confirm = false;
          try {
            this.process = false;
            this.$emit("alertError", e.response.data.message);
          } catch (e) {
            this.$emit(
              "alertError",
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
    listBranches() {
      this.loadingBranchList = true;
      return branchService.get({
        branchName: null,
        branchState: "Active",
        pageIndex: 1,
        pageSize: 100,
      });
    },
    setSelectedProduct(id) {
      const p = this.products.find((item) => item.loanProductKey === id);
      this.loanProduct = p;
      this.loanProductName = p.productName;
      this.loanAmount = p.defaultLoanAmount ? p.defaultLoanAmount : 0;
      this.maxLoanAmount = p.maxLoanAmount ? p.maxLoanAmount : 0;
      this.minLoanAmount = p.minLoanAmount ? p.minLoanAmount : 0;
      this.repaymentPeriodUnit = p.repaymentPeriodUnit ? p.minLoanAmount : 0;
      this.repaymentInstallments = p.defaultFirstRepaymentDueDateOffset
        ? p.defaultFirstRepaymentDueDateOffset
        : 0;
      this.repaymentScheduleMethod = p.repaymentScheduleMethod
        ? p.repaymentScheduleMethod
        : null;
      this.interestCalculationMethod = p.interestCalculationMethod
        ? p.interestCalculationMethod
        : null;
      this.interestRate = p.adminInterestProductSettings
        ? p.adminInterestProductSettings.defaultInterestRate
          ? p.adminInterestProductSettings.defaultInterestRate
          : 0
        : 0;
      this.arrearsTolerancePeriod = p.adminArrearsResponseModel
        ? p.adminArrearsResponseModel.defaultTolerancePeriod
          ? p.adminArrearsResponseModel.defaultTolerancePeriod
          : 0
        : 0;
      this.defaultFirstRepaymentDueDateOffset = p.adminArrearsResponseModel
        ? p.adminArrearsResponseModel.defaultInterestRate
          ? p.adminArrearsResponseModel
              .defaultTolerancePercentageOfOutstandingPrincipal
          : 0
        : 0;
    },
  },
  watch: {
    productKey: function (id) {
      this.setSelectedProduct(id);
    },
    show(change) {
      if (change) {
        if (window.location.hash.indexOf("customer") != -1) {
          this.whatPage = "customer";
        }
      }
    },
  },
  computed: mapState({
    enums: (state) => state.loan.enums,
    loading: (state) => state.loan.loading_all,
    products: (state) =>
      state.loan.products_all.data ? state.loan.products_all.data : [],
    saving: (state) => state.loan.saving,
    loanProductDescription() {
      return this.loanProduct ? this.loanProduct.productDescription : "";
    },
    loanMinInterestRate() {
      return this.loanProduct
        ? this.loanProduct.adminInterestProductSettings
          ? this.loanProduct.adminInterestProductSettings.minInterestRate
          : 0
        : 0;
    },
    loanMaxInterestRate() {
      return this.loanProduct
        ? this.loanProduct.adminInterestProductSettings
          ? this.loanProduct.adminInterestProductSettings.maxInterestRate
          : 0
        : 0;
    },
    minFirstRepaymentDueDateOffset() {
      return this.loanProduct
        ? this.loanProduct.minFirstRepaymentDueDateOffset
          ? this.loanProduct.minFirstRepaymentDueDateOffset
          : 0
        : 0;
    },
    maxFirstRepaymentDueDateOffset() {
      return this.loanProduct
        ? this.loanProduct.maxFirstRepaymentDueDateOffset
          ? this.loanProduct.maxFirstRepaymentDueDateOffset
          : 0
        : 0;
    },
    maxTolerancePercentageOfOutsatndingPrincipal() {
      return this.loanProduct
        ? this.loanProduct.adminArrearsResponseModel
          ? this.loanProduct.maxTolerancePercentageOfOutsatndingPrincipal
          : 0
        : 0;
    },
    minTolerancePercentageOfOutsatndingPrincipal() {
      return this.loanProduct
        ? this.loanProduct.adminArrearsResponseModel
          ? this.loanProduct.minTolerancePercentageOfOutsatndingPrincipal
          : 0
        : 0;
    },
    loanProductType() {
      return this.loanProduct
        ? this.loanProduct.loanProductType.replace(/_/g, " ")
        : "";
    },
    loanInterestType(state) {
      if (this.loanProduct) {
        const hh = state.loan.enums.repaymentAreMadeEvery.find(
          (h) => h.id == this.loanProduct.repaymentScheduleMethod
        );
        return hh.name;
      }
    },
    loanInterestCalculationMethod(state) {
      if (this.loanProduct) {
        const hh = state.loan.enums.interestCalculationMethod.find(
          (h) => h.id == this.loanProduct.interestCalculationMethod
        );
        return hh.name;
      }
    },
    accountRecipient() {
      if (this.customer) {
        if (this.customer.customerFullname) {
          return this.customer.customerFullname;
        }
      }
      return "";
    },
    repaymentAreMadeEvery() {
      const self = this;
      return this.enums.repaymentAreMadeEvery.filter((unit) => {
        if (unit.id == 0 || unit.id == 1) return true;
        if ((unit.id == 2 || unit.id == 3) && self.daysInYear == 2) {
          return true;
        } else {
          return false;
        }
      });
    },
  }),
  mounted() {
    const self = this;
    this.listBranches().then((res) => {
      self.loadingBranchList = false;
      if (res.status === 200) {
        self.branchList = res.data.data.items;
      }
    });
    this.getLoanProducts();
  },
};
</script>
